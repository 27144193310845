<template>
  <el-form
    class="contract-sign-guest-form"
    :model="contract"
    :rules="rules"
    :class="{ mobile: !$full }"
    ref="form"
  >
    <div class="title">{{ $t('contract.formTitle') }}</div>
    <div class="row">
      <el-form-item prop="firstName">
        <el-input-plain
          :placeholder="$t('settings.profile.firstName')"
          v-model="contract.firstName"
        />
      </el-form-item>
      <el-form-item prop="lastName">
        <el-input-plain
          :placeholder="$t('settings.profile.lastName')"
          v-model="contract.lastName"
        />
      </el-form-item>
    </div>
    <div class="row">
      <el-form-item
        prop="middleName"
        v-if="['ru', 'by'].includes(contract.citizenship)"
      >
        <el-input-plain
          :placeholder="$t('settings.profile.middleName')"
          v-model="contract.middleName"
        />
      </el-form-item>
      <el-form-item prop="birthDate">
        <el-input-plain
          :placeholder="$t('settings.profile.birthDate')"
          v-model="contract.birthDate"
          mask="##.##.####"
        />
      </el-form-item>
    </div>
    <div class="row">
      <el-form-item prop="birthPlace">
        <el-input-plain
          :placeholder="$t('settings.profile.birthPlace')"
          v-model="contract.birthPlace"
          :allowNumbers="false"
        />
      </el-form-item>
    </div>
    <div class="row">
      <el-form-item prop="isChild">
        <el-select v-model="contract.isChild">
          <el-option :label="$t('contract.isAdult')" :value="false" />
          <el-option :label="$t('contract.isChild')" :value="true" />
        </el-select>
      </el-form-item>
      <el-form-item prop="gender">
        <el-select v-model="contract.gender">
          <el-option :label="$t('contract.male')" :value="0" />
          <el-option :label="$t('contract.female')" :value="1" />
        </el-select>
      </el-form-item>
    </div>

    <div class="title">{{ $t('contract.citizenship') }}</div>
    <div class="row">
      <el-form-item prop="citizenship">
      <el-select v-model="contract.citizenship">
        <el-option :label="$t('contract.citizenshipRussia')" value="ru" />
        <el-option :label="$t('contract.citizenshipBelarus')" value="by" />
        <el-option :label="$t('contract.citizenshipAzerbaijan')" value="az" />
        <el-option :label="$t('contract.citizenshipArmenia')" value="am" />
        <el-option :label="$t('contract.citizenshipGermany')" value="de" />
        <el-option :label="$t('contract.citizenshipGeorgia')" value="ge" />
        <el-option :label="$t('contract.citizenshipSpain')" value="es" />
        <el-option :label="$t('contract.citizenshipKazakhstan')" value="kz" />
        <el-option :label="$t('contract.citizenshipChina')" value="cn" />
        <el-option :label="$t('contract.citizenshipMoldova')" value="md" />
        <el-option :label="$t('contract.citizenshipSerbia')" value="rs" />
        <el-option :label="$t('contract.citizenshipTajikistan')" value="tj" />
        <el-option :label="$t('contract.citizenshipThailand')" value="th" />
        <el-option :label="$t('contract.citizenshipTurkmenistan')" value="tm" />
        <el-option :label="$t('contract.citizenshipTurkey')" value="tr" />
        <el-option :label="$t('contract.citizenshipUkraine')" value="ua" />
        <el-option :label="$t('contract.citizenshipUzbekistan')" value="uz" />
        <el-option :label="$t('contract.citizenshipFrance')" value="fr" />
        <el-option :label="$t('contract.citizenshipEstonia')" value="ee" />
      </el-select>
    </el-form-item>
    </div>
    <div class="title">{{ $t('contract.identifyDocument') }}</div>
    <div class="row">
      <el-form-item prop="documentType">
        <el-select v-model="contract.documentType">
          <el-option :label="$t('contract.passport')" value="passport" />
          <el-option
            :label="$t('contract.bdcertificate')"
            value="bdcertificate"
            v-if="contract.citizenship == 'ru'"
          />
        </el-select>
      </el-form-item>
    </div>
    <div class="row">
      <el-form-item prop="documentSeries" v-if="contract.citizenship == 'ru'">
        <el-input-plain
          v-if="contract.documentType == 'passport'"
          :placeholder="$t('contract.documentSeries')"
          v-model="contract.documentSeries"
          mask="####"
        />
        <el-input-plain
          v-else-if="contract.documentType == 'bdcertificate'"
          :placeholder="$t('contract.documentSeries')"
          v-model="contract.documentSeries"
          mask="AAAAAAAAAAAAA"
          :tokens="{ A: { pattern: /^[A-Za-zА-ЯЁа-яё-]+$/ } }"
        />
      </el-form-item>
      <el-form-item prop="documentNumber">
        <el-input-plain
          :placeholder="$t('contract.documentNumber')"
          v-model="contract.documentNumber"
          :mask="contract.citizenship == 'ru' ? '######' : 'AX########'"
          :onlyUppercase="true"
        />
      </el-form-item>
    </div>
    <div class="row">
      <el-form-item
        prop="documentDivision"
        v-if="
          contract.documentType == 'passport' && contract.citizenship == 'ru'
        "
      >
        <el-input-plain
          :placeholder="$t('contract.passportDivision')"
          v-model="contract.documentDivision"
          mask="###-###"
        />
      </el-form-item>
      <el-form-item prop="documentIssuedBy">
        <el-input-plain
          :placeholder="$t('contract.passportIssuedBy')"
          v-model="contract.documentIssuedBy"
        />
      </el-form-item>
    </div>
    <div class="row">
      <el-form-item prop="documentIssuedDate">
        <el-input-plain
          :placeholder="$t('contract.passportIssuedDate')"
          v-model="contract.documentIssuedDate"
          mask="##.##.####"
        />
      </el-form-item>
    </div>

    <div class="row" v-if="['ru', 'by'].includes(contract.citizenship)">
      <el-form-item prop="address">
        <el-input-plain
          @click.native="updateAddressSuggestions"
          :placeholder="$t('contract.address')"
          v-model="contract.address"
        />
        <div class="address-suggestions" v-if="addressSuggestions.length > 0">
          <div
            class="suggestion"
            :class="{ invalid: !suggestion.valid }"
            :key="suggestion.address"
            v-for="suggestion in addressSuggestions"
            @click="selectSuggestion(suggestion)"
          >
            {{ suggestion.address }}
          </div>
          <div class="suggestions-out" @click="addressSuggestions = []" />
        </div>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import getAge from '../../lib/getAge'
import { parseDateFromDayString } from '../../lib/formatDate'
import FormAddressSuggestionsMixin from '@/mixins/ContractSign/FormAddressSuggestions'

export default {
  props: ['contract', 'mainContract', 'id'],
  mixins: [FormAddressSuggestionsMixin],
  data() {
    return {
      rules: {
        lastName: [
          {
            required: true,
            message: this.$t('contract.enterLastName'),
          },
          {
            validator: (rule, name, cb) => {
              cb(
                this.contract.citizenship == 'ru' &&
                  /[^\u0400-\u04FF\s-]/.test(name)
                  ? new Error(this.$t('contract.onlyCanHaveCyrillic'))
                  : undefined,
              )
            },
          },
        ],
        firstName: [
          {
            required: true,
            message: this.$t('contract.enterFirstName'),
          },
          {
            validator: (rule, name, cb) => {
              cb(
                this.contract.citizenship == 'ru' &&
                  /[^\u0400-\u04FF\s-]/.test(name)
                  ? new Error(this.$t('contract.onlyCanHaveCyrillic'))
                  : undefined,
              )
            },
          },
        ],
        middleName: [
          {
            required: true,
            message: this.$t('contract.enterMiddleName'),
          },
          {
            validator: (rule, name, cb) => {
              cb(
                this.contract.citizenship == 'ru' &&
                  /[^\u0400-\u04FF\s-]/.test(name)
                  ? new Error(this.$t('contract.onlyCanHaveCyrillic'))
                  : undefined,
              )
            },
          },
        ],
        birthDate: [
          {
            required: true,
            message: this.$t('contract.enterBirthDate'),
          },
          {
            validator: (rule, dateString, cb) => {
              const date = parseDateFromDayString(dateString)
              if (date == null)
                cb(new Error(this.$t('contract.enterBirthDate')))

              cb(
                !this.contract.isChild && getAge(date) < 18
                  ? new Error(this.$t('contract.birthDateIncorrect'))
                  : undefined,
              )
            },
          },
        ],
        birthPlace: [
          {
            required: true,
            message: this.$t('contract.enterBirthPlace'),
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('contract.enterAddress'),
          },
          {
            validator: (rule, address, cb) => {
              cb(
                address == this.lastSelectedSuggestion?.address &&
                  this.lastSelectedSuggestion?.valid
                  ? undefined
                  : new Error(this.$t('contract.addressIncorrectWithHouse')),
              )
            },
          },
        ],
        documentSeries: [
          {
            required: true,
            message: this.$t('contract.enterDocumentSeries'),
            validator: (rule, value, cb) => {
              if (this.contract.documentType == 'passport') {
                cb(
                  value.length < '####'.length
                    ? new Error(this.$t('contract.enterDocumentSeries'))
                    : undefined,
                )
              } else {
                cb(
                  !value.includes('-')
                    ? new Error(this.$t('contract.enterDocumentSeries'))
                    : undefined,
                )
              }
            },
          },
        ],
        documentNumber: [
          {
            required: true,
            message: this.$t('contract.enterDocumentNumber'),
            validator: (rule, value, cb) => {
              cb(
                (this.contract.citizenship == 'ru'
                ? value.length < '######'.length
                : value.length < 'A#######'.length)
                  ? new Error(this.$t('contract.enterDocumentNumber'))
                  : undefined,
              )
            },
          },
        ],
        documentDivision: [
          {
            required: true,
            message: this.$t('contract.enterPassportDivision'),
            validator: (rule, value, cb) => {
              cb(
                value.length < '###-###'.length &&
                  this.contract.documentType == 'passport'
                  ? new Error(this.$t('contract.enterPassportDivision'))
                  : undefined,
              )
            },
          },
        ],
        documentIssuedBy: [
          {
            required: true,
            message: this.$t('contract.enterPassportIssuedBy'),
          },
        ],
        documentIssuedDate: [
          {
            required: true,
            message: this.$t('contract.enterPassportIssuedDate'),
          },
          {
            validator: (rule, dateString, cb) => {
              const date = parseDateFromDayString(dateString)
              if (date == null)
                cb(new Error(this.$t('contract.enterPassportIssuedDate')))

              cb(
                date >= new Date()
                  ? new Error(this.$t('contract.dateCantBeInFuture'))
                  : undefined,
              )
            },
          },
        ],
      },
    }
  },
  watch: {
    'contract.documentSeries'(series) {
      if (series != series.toUpperCase())
        this.contract.documentSeries = series.toUpperCase()
    },
    'contract.citizenship'(citizenship) {
      if (citizenship != 'ru') this.contract.documentType = 'passport'
      if (!['ru', 'by'].includes(citizenship)) this.contract.middleName = ''
      this.$refs.form.validateField('firstName')
      this.$refs.form.validateField('lastName')
      this.$refs.form.validateField('middleName')
    },
    'contract.isChild'() {
      this.$refs.form.validateField('birthDate')
    },
  },
}
</script>

<style lang="scss">
.contract-sign-guest-form {
  &.mobile {
    > .row {
      flex-direction: column;
      gap: 0;
    }
  }

  > .title {
    font-size: 21px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 15px 0;
    &:nth-of-type(1) {
      margin-top: 0;
    }

    > .delete-guest-button {
      padding: 4px;
    }
  }

  > .row {
    flex-wrap: initial;
    margin: initial;
    display: flex;
    gap: 10px;

    > .el-form-item {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
      transition: 0.2s margin-bottom;
      &.is-error {
        margin-bottom: 22px;
      }

      &:has(.address-suggestions) {
        .el-input {
          z-index: 3;
        }
      }

      .address-suggestions {
        z-index: 2;
        position: absolute;
        background-color: white;
        box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;

        > .suggestion {
          padding: 0 15px;
          cursor: pointer;
          background-color: inherit;
          transition: 0.2s background-color;

          &.invalid {
            color: #ccc;
            cursor: default;
          }

          &:hover:not(.invalid) {
            background-color: rgba(220, 220, 220);
          }
        }
        > .suggestions-out {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          background-color: rgba(0, 0, 0, 0.025);
        }
      }

      .el-date-editor,
      .el-select {
        width: 100%;
      }
      .el-link {
        vertical-align: top;
        display: inline;

        &:hover {
          text-decoration: underline !important;
        }
      }
      .el-checkbox {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .el-checkbox__label {
          color: #606266;
          white-space: pre-wrap;
        }
      }

      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
